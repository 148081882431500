// src/components/Header.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../App.css';
import { useAuth } from '../contexts/AuthContext';
import { AiOutlineHeart, AiOutlineClose } from 'react-icons/ai';
import { FiUser } from 'react-icons/fi';
import { BsChatDots } from 'react-icons/bs';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { getChats } from '../utils/db';

const Header = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [savedCount, setSavedCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const savedCars = JSON.parse(localStorage.getItem('savedCars')) || {};
    setSavedCount(Object.keys(savedCars).length);

    if (currentUser) {
      fetchUnreadMessageCount();
    }
  }, [currentUser, location.pathname]);

  const fetchUnreadMessageCount = async () => {
    try {
      const chats = await getChats();
      const totalUnread = Array.isArray(chats) 
        ? chats.reduce((sum, chat) => sum + (chat.unread_count || 0), 0)
        : 0;
      setUnreadMessageCount(totalUnread);
    } catch (error) {
      console.error('Error fetching unread message count:', error);
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const mobileMenuItems = [
    { title: 'Бүх Зарууд', link: '/listings' },
    { title: 'Машинаа Зарах', link: '/sell-my-car' },
  ];

  return (
    <header className="header">
      <div className="mobile-header">
        <div className="mobile-logo">
          <Link to="/">softmax</Link>
        </div>
        <div className="mobile-buttons">
          {currentUser ? (
            <Link to="/account" className="mobile-user-icon"><FiUser size={24} /></Link>
          ) : (
            <Link to="/signup" className="mobile-sign-up">Sign up</Link>
          )}
          <button className="mobile-menu-button" onClick={toggleMobileMenu}>
            {mobileMenuOpen ? <AiOutlineClose size={24} /> : <span>☰</span>}
          </button>
        </div>
      </div>

      {mobileMenuOpen && (
        <div className="mobile-menu">
          {mobileMenuItems.map((item, index) => (
            <Link key={index} to={item.link} className="mobile-menu-item" onClick={toggleMobileMenu}>
              {item.title}
            </Link>
          ))}
          {currentUser ? (
            <>
              <Link to="/saved" className="mobile-menu-item" onClick={toggleMobileMenu}>
                Хадгалсан ({savedCount})
              </Link>
              <Link to="/chat" className="mobile-menu-item" onClick={toggleMobileMenu}>
                Чат {unreadMessageCount > 0 && `(${unreadMessageCount})`}
              </Link>
              <Link to="/account" className="mobile-menu-item" onClick={toggleMobileMenu}>
                Хаяг
              </Link>
            </>
          ) : (
            <div className="mobile-auth-buttons">
              <Link to="/login" className="mobile-sign-in" onClick={toggleMobileMenu}>
                Нэвтрэх
              </Link>
              <Link to="/signup" className="mobile-sign-up" onClick={toggleMobileMenu}>
                Бүртгүүлэх
              </Link>
            </div>
          )}
        </div>
      )}

      <div className="desktop-header">
        <div className="logo">
          <Link to="/">softmax</Link>
        </div>
        <nav>
          <ul>
            <li><Link to="/listings">Бүх Зарууд</Link></li>
            <li><Link to="/sell-my-car">Машинаа Зарах</Link></li>
          </ul>
        </nav>
        <div className="auth-buttons">
          {currentUser ? (
            <div className="icon-container">
              <Link to="/saved" className="icon-link">
                <AiOutlineHeart size={24} />
                <span>Хадгалсан ({savedCount})</span>
              </Link>
              <Link to="/chat" className="icon-link">
                <BsChatDots size={24} />
                <span>Чат {unreadMessageCount > 0 && `(${unreadMessageCount})`}</span>
              </Link>
              <Link to="/account" className="icon-link">
                <FiUser size={24} />
                <span>Хаяг</span>
              </Link>
            </div>
          ) : (
            <>
              <Link to="/login"><button className="sign-in">Нэвтрэх</button></Link>
              <Link to="/signup"><button className="sign-up">Бүртгүүлэх</button></Link>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;