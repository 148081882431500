// src/components/PurchaseSlots.js

import React, { useState } from 'react';
import { 
  Button, Dialog, DialogActions, DialogContent, DialogContentText, 
  DialogTitle, TextField, Typography, CircularProgress, Alert, Box,
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { initiateSlotPurchase, initiateKhanBankPayment } from '../utils/db'; // Import new function

const PurchaseSlots = ({ open, onClose, onPurchase, onInitiatePayment }) => {
  const [selectedSlots, setSelectedSlots] = useState(1);
  const [cost, setCost] = useState(5000);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('qpay'); // New state for payment method

  const PRICE_PER_SLOT = 5000;

  useState(() => {
    setCost(selectedSlots * PRICE_PER_SLOT);
  }, [selectedSlots]);

  const handleSlotsChange = (event) => {
    const value = parseInt(event.target.value);
    if (value > 0) {
      setSelectedSlots(value);
      setCost(value * PRICE_PER_SLOT);
    }
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handlePurchase = async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (paymentMethod === 'qpay') {
        const response = await initiateSlotPurchase(selectedSlots);
        onInitiatePayment(response.qr_code_url, response.qpay_short_url, response.payment_id, response.invoice_id);
      } else if (paymentMethod === 'khanbank') {
        const response = await initiateKhanBankPayment(selectedSlots);
        window.open(response.formUrl, '_blank');
        onInitiatePayment(null, null, response.payment_id, null);
      }
      onPurchase();
      onClose();
    } catch (error) {
      console.error('Error purchasing slots:', error);
      setError('Failed to purchase slots. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Нэмэлт Эрх Худалдаж Авах</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Та хэдэн эрх худалдаж авахыг хүсэж байна вэ?
        </DialogContentText>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
          <TextField
            type="number"
            label="Эрхийн тоо"
            value={selectedSlots}
            onChange={handleSlotsChange}
            inputProps={{ min: 1 }}
            sx={{ mr: 2 }}
          />
          <Typography variant="body1">
            Үнэ: ₮{cost.toLocaleString()}
          </Typography>
        </Box>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="payment-method-label">Төлбөрийн Арга</InputLabel>
          <Select
            labelId="payment-method-label"
            value={paymentMethod}
            label="Төлбөрийн Арга"
            onChange={handlePaymentMethodChange}
          >
            <MenuItem value="qpay">QPay</MenuItem>
            <MenuItem value="khanbank">Khan Bank</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          1 эрхийн үнэ: ₮{PRICE_PER_SLOT.toLocaleString()}
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isLoading}>
          Цуцлах
        </Button>
        <Button onClick={handlePurchase} color="primary" disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Худалдаж Авах'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PurchaseSlots;
